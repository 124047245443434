/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties, FC, MutableRefObject, ReactElement, createElement, useEffect, useMemo, useRef, useState } from 'react';
import { Option } from '../Option';
import { Input, InputStyle } from './form-control/Input';
import { useTranslation } from 'react-i18next';
import { FCWithChildren } from '../../types/FCWithChildren';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import {
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import StringUtils from '../../utils/StringUtils';

// Dropdown to be paired with other inputs and data sources - doesn't do much on it's own
type Item = Option<string, string | number | readonly number[] | boolean>;

type SelectListMenuProps<TItem extends Item> = {
  id?: string;
  options: TItem[];
  instruction?: string;
  className?: string;
  style?: CSSProperties;
  isOpen: boolean;
  isFixed?: boolean;
  noMatchMessage?: string | ReactElement | FC;
  onClick?: (option: TItem) => void;
  customListItemRenderer?: FC<TItem & { highlightedText?: string }>;
  listWrapper?: FCWithChildren;
  selectedIndex?: number;
  width?: string;
  onBlur?: () => void;
  enableSearching?: boolean;
  autoStealFocusOnOpen?: boolean;
  placement?: Placement;

  children?: (props: Record<string, unknown>) => ReactElement;
  containingRef?: MutableRefObject<any>;
  footer?: string | ReactElement | FC;

  highlightedText?: string;
  blurOnClick?: boolean;
};

const defaultListWrapper: FCWithChildren = ({ children }) => <ul>{children}</ul>;

export const SelectListMenu = <TItem extends Item>(props: SelectListMenuProps<TItem>) => {
  const {
    id,
    options,
    instruction,
    onClick,
    className,
    style,
    isOpen,
    noMatchMessage,
    customListItemRenderer,
    listWrapper: ListWrapper = defaultListWrapper,
    selectedIndex,
    width,
    onBlur,
    enableSearching,
    containingRef,
    children,
    footer,
    autoStealFocusOnOpen = true,
    placement,
    highlightedText,
    blurOnClick = true,
  } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation('common');

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (o) => !o && onBlur && onBlur(),
    middleware: [offset(10), flip(), shift(), size()],
    whileElementsMounted: autoUpdate,
    placement,
    strategy: 'fixed',
  });

  const click = useClick(context, { keyboardHandlers: false });
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const focus = useFocus(context);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([click, dismiss, role, focus]);

  useEffect(() => {
    if (containingRef?.current) {
      refs.setReference(containingRef?.current);
    }
  }, [containingRef, refs]);

  const filteredOptions = useMemo(() => {
    if (!searchTerm) {
      return options;
    }

    const search = searchTerm.toLocaleLowerCase();
    return options.filter((opt) => opt.text.toLocaleLowerCase().indexOf(search) > -1);
  }, [options, searchTerm]);

  useEffect(() => {
    if (!isOpen) {
      setSearchTerm('');
    }
  }, [isOpen]);

  const prevFocus = useRef<HTMLElement | null>(null);
  useEffect(() => {
    const menu = refs.floating.current;
    if (!filteredOptions.length || !menu || !autoStealFocusOnOpen) return;

    if (isOpen) {
      prevFocus.current = document.activeElement as HTMLElement;

      const listItems = [...menu.querySelectorAll('li')];
      const firstItem = listItems[0];
      const lastItem = listItems[listItems.length - 1];

      firstItem.focus();

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowDown') {
          const nextItem = document.activeElement?.nextElementSibling as HTMLElement;
          nextItem?.focus();
          e.preventDefault();
        } else if (e.key === 'ArrowUp') {
          const prevItem = document.activeElement?.previousElementSibling as HTMLElement;
          prevItem?.focus();
          e.preventDefault();
        } else if (e.key === 'Escape') {
          onBlur && onBlur();
        } else if (e.key === 'Tab') {
          if (e.shiftKey && document.activeElement === firstItem) {
            e.preventDefault();
            prevFocus.current?.focus();
            prevFocus.current = null;
            onBlur && onBlur();
          } else if (!e.shiftKey && document.activeElement === lastItem) {
            e.preventDefault();
            prevFocus.current?.focus();
            prevFocus.current = null;
            onBlur && onBlur();
          }
        }
      };

      menu.addEventListener('keydown', handleKeyDown);
      return () => {
        menu.removeEventListener('keydown', handleKeyDown);
      };
    } else {
      prevFocus.current?.focus();
      prevFocus.current = null;
    }
  }, [autoStealFocusOnOpen, onBlur, filteredOptions, isOpen, refs.floating]);

  return (
    <>
      {children &&
        children({
          ref: containingRef?.current ? undefined : refs.setReference,
          ...getReferenceProps(),
        })}
      {isOpen && (
        <div
          id={id}
          ref={refs.setFloating}
          style={{ ...floatingStyles, zIndex: 9999, maxWidth: refs.reference.current?.getBoundingClientRect().width, ...style }}
          {...getFloatingProps()}
          className={`${width || 'w-full'} min-w-dropdown-box border-1 max-h-96 overflow-y-auto rounded-md border-gray-200 bg-white shadow-md empty:border-0 ${className}`}
          data-cy={`select-list-menu${(refs.reference.current as HTMLElement)?.getAttribute('data-cy') ? '-' + (refs.reference.current as HTMLElement)?.getAttribute('data-cy') : ''}`}
        >
          {instruction && (
            <>
              {/* eslint-disable-next-line jsx-a11y/prefer-tag-over-role */}
              <div className="text-primary-1 px-4 py-2" data-cy="instruction" role="presentation">
                {instruction}
              </div>
              <hr className="border-gray-5 border-0 border-b-2" />
            </>
          )}
          {filteredOptions.length === 0 && noMatchMessage && (
            <div className="px-4 py-1 italic" data-cy="no-match" role="alert" aria-live="assertive">
              {typeof noMatchMessage === 'function' ? createElement(noMatchMessage) : noMatchMessage}
            </div>
          )}
          {enableSearching && (
            <div className="px-1 py-2">
              <Input
                style={InputStyle.MINIMAL}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={t('list.filter.search')}
                aria-label={t('aria-label.search')}
              />
            </div>
          )}
          <ListWrapper>
            {filteredOptions?.map((option, i) => {
              if (option.hasDivider) {
                return (
                  <li
                    key={option.id}
                    className={`px-3 font-thin ${i === 0 ? 'mt-1' : 'mt-3'} border-gray-5 sticky top-0 mb-2 border-b-2 bg-white pb-1`}
                  >
                    {option.text}
                  </li>
                );
              } else {
                return (
                  <li
                    {...getItemProps({
                      ...mouseAndKeyboardCallbackProps(
                        option.disabled
                          ? undefined
                          : (e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onClick && onClick(option);
                              blurOnClick && onBlur && onBlur();
                            },
                      ),
                    })}
                    data-cy={`option-${option.id}`}
                    className={`select-none px-4 py-2 ${onClick ? 'cursor-pointer' : 'cursor-default'} ${selectedIndex === i ? 'bg-gray-100' : ''} ${
                      option.disabled ? 'cursor-default opacity-50' : ''
                    } text-primary-1 ${onClick ? 'hover:bg-gray-100 hover:text-black' : ''}`}
                    key={`${option.id}-${option.text}-${option.value}`}
                  >
                    {customListItemRenderer
                      ? createElement(customListItemRenderer, { ...option, highlightedText })
                      : StringUtils.highlightText(option.text, highlightedText)}
                  </li>
                );
              }
            })}
          </ListWrapper>
          <div className="sticky bottom-0 bg-white p-1 empty:hidden">{typeof footer === 'function' ? createElement(footer) : footer}</div>
        </div>
      )}
    </>
  );
};
